import {AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { GrabarService } from '../services/grabar.service';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css']
})
export class LiveComponent implements AfterViewInit {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('contenidoTransmicion') contenidoTransmicion!: ElementRef<HTMLDivElement>
  public remoteStream: MediaStream | undefined;

  constructor(private grabarService: GrabarService) {}

  ngOnInit() {
    this.grabarService.remoteStreamSubject.subscribe(stream => {
      this.remoteStream = stream;
      console.log(stream);
      this.attachRemoteStream();
    });
  }

  ngAfterViewInit() {
    this.attachRemoteStream();
    this.enableDrag();
    // this.enableDragAndTouch();
  }

  private attachRemoteStream() {
    if (this.videoElement && this.remoteStream) {
      this.videoElement.nativeElement.srcObject = this.remoteStream;
    }
  }

  // mover el video en mi pantalla pc
  private enableDrag() {
    const contenidoTransmicion = this.contenidoTransmicion.nativeElement;

    contenidoTransmicion.addEventListener('dragstart', (event) => {
      event.dataTransfer?.setData('text/plain', '');
    });

    contenidoTransmicion.addEventListener('dragend', (event) => {
      const x = event.clientX;
      const y = event.clientY;

      contenidoTransmicion.style.left = `${x}px`;
      contenidoTransmicion.style.top = `${y}px`;
    });
  }
  // mover el video en mi pantalla movil
  private enableDragAndTouch() {
    const contenidoTransmicion = this.contenidoTransmicion.nativeElement;

    // Drag events for desktop
    contenidoTransmicion.addEventListener('dragstart', (event) => {
      event.dataTransfer?.setData('text/plain', '');
    });

    contenidoTransmicion.addEventListener('dragend', (event) => {
      const x = event.clientX;
      const y = event.clientY;

      contenidoTransmicion.style.left = `${x}px`;
      contenidoTransmicion.style.top = `${y}px`;
    });

    // Touch events for mobile
    contenidoTransmicion.addEventListener('touchstart', (event) => {
      event.preventDefault();
    });

    contenidoTransmicion.addEventListener('touchmove', (event) => {
      event.preventDefault();
      const touch = event.touches[0];
      const x = touch.clientX;
      const y = touch.clientY;

      contenidoTransmicion.style.left = `${x}px`;
      contenidoTransmicion.style.top = `${y}px`;
    });
  }
}
