import { Component , OnInit} from '@angular/core';
import { WebsocketService } from '../services/websocket.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{

  private datosCargados: boolean = false;

  tablaBingo: any[][] = [];
  numeros: number[] = [];
  numerosSeleccionados: number[] = [];
  numeroGenerado: number = 0;

  numeroJugadores: number = 2;
  maxGanadores: number = 1;
  apuesta: number = 2;

  constructor(private websocketService: WebsocketService) {}

  ngOnInit() {
    // Puedes poner lógica de inicialización aquí si es necesario
    // Suscribirse a mensajes del WebSocket
    this.websocketService.getMensajes().subscribe(mensaje => {
      if (mensaje['tipo']=='datosBingo') {
        this.tablaBingo=mensaje.tablaBingo;
        this.numeros=mensaje.numeros;
        this.numerosSeleccionados=mensaje.numerosSeleccionados;
        this.numeroGenerado=mensaje.numeroGenerado;

        this.numeroJugadores=mensaje.numeroJugadores;
        this.maxGanadores=mensaje.maxGanadores;
        this.apuesta=mensaje.apuesta;
      }

      if (mensaje['tipo']=='numeroGenerado') {
        this.numeroGenerado=mensaje.numeroGenerado;
      }

      if (mensaje['tipo']=='tablaBingo') {
        this.tablaBingo[mensaje['e']][mensaje['fil']]['nombre']=mensaje['nuevoNombre'];
      }

      if (mensaje['tipo']=='puntos') {
        this.tablaBingo[mensaje['e']][mensaje['fil']]['puntos']=mensaje['puntos'];
        this.numerosSeleccionados=mensaje.numerosSeleccionados;
      }

      if (mensaje['tipo']=='numeroGanadore') {
        this.numeros=mensaje.numeros;
      }

    });
    setTimeout(() => {
      if (!this.datosCargados) {
        this.traerDatos();
        this.datosCargados = true;  // Actualizar la variable de control
      }
    }, 300);
  }

  traerDatos() {
    let datosEnviar = {
      tipo: 'traerDatos'
    };
    this.websocketService.enviarMensaje(JSON.stringify(datosEnviar));
  }

  getArray(length: number): any[] {
    if (length==0) {
      return [];
    }
    return new Array(length).fill(0);
  }

}
