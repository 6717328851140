import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketResolverService implements Resolve<boolean> {

  constructor(private websocketService: WebsocketService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      const checkWebSocketReady = () => {
        if (this.websocketService.isReady()) {
          resolve(true);
        } else {
          // Retry if not ready
          setTimeout(checkWebSocketReady, 100); // Check again after 100ms
        }
      };

      checkWebSocketReady();
    });
  }
}
