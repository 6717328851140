import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class GrabarService {
  private peerConnection: RTCPeerConnection | undefined;
  public remoteStreamSubject = new Subject<MediaStream>();
  private localStream: MediaStream | undefined;

  private mediaRecorder: MediaRecorder | undefined;
  private recordedChunks: Blob[] = [];
  private iceCandidatesQueue: RTCIceCandidate[] = [];

  constructor(private websocketService: WebsocketService) {
    this.websocketService.getMensajes().subscribe(message => this.handleSignalingData(message));
  }

  async startRecording(): Promise<MediaStream> {
    const displayMediaOptions: DisplayMediaStreamOptions = {
      video: {
        cursor: "motion",
        width: { ideal: 1920 }, // Ajusta el ancho deseado
        height: { ideal: 1080 }, // Ajusta la altura deseada
        frameRate: { ideal: 30 } // Ajusta los cuadros por segundo
         } as MediaTrackConstraints,
      audio: false
    };
    const mediaStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
    const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });

    audioStream.getAudioTracks().forEach(track => mediaStream.addTrack(track));

    const mediaRecorderOptions = {
      mimeType: 'video/webm; codecs=vp9' // Usa VP9 para una mejor compresión, ajusta según sea necesario
    };

    this.mediaRecorder = new MediaRecorder(mediaStream, mediaRecorderOptions);

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        this.recordedChunks.push(event.data);
      }
    };

    this.mediaRecorder.start();
    this.localStream = mediaStream;
    return mediaStream;
  }

  stopRecording(): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      if (this.mediaRecorder) {
        this.mediaRecorder.onstop = () => {
          const recordedBlob = new Blob(this.recordedChunks, { type: 'video/webm' });
          this.recordedChunks = []; // Limpiar los datos grabados
          resolve(recordedBlob);
        };
        this.mediaRecorder.stop();
      } else {
        reject(new Error("No hay una grabación en progreso."));
      }
    });
  }

// ---------------------------------------------------------------------------------------------

  public async startCall() {
    if (!this.localStream) throw new Error('Local stream not available');

    this.peerConnection = new RTCPeerConnection();

    this.localStream.getTracks().forEach(track => this.peerConnection!.addTrack(track, this.localStream!));

    this.peerConnection.ontrack = (event) => {
      this.remoteStreamSubject.next(event.streams[0]);
    };

    this.peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        this.websocketService.enviarMensaje(JSON.stringify({ iceCandidate: event.candidate }));
      }
    };

    const offer = await this.peerConnection.createOffer();
    await this.peerConnection.setLocalDescription(offer);
    this.websocketService.enviarMensaje(JSON.stringify({ offer }));
  }

  // private async handleSignalingData(data: any) {
  //   if (data.offer) {
  //     if (!this.peerConnection) {
  //       this.peerConnection = new RTCPeerConnection();
  //     }
  //     await this.peerConnection.setRemoteDescription(new RTCSessionDescription(data.offer));
  //     const answer = await this.peerConnection.createAnswer();
  //     await this.peerConnection.setLocalDescription(answer);
  //     this.websocketService.enviarMensaje(JSON.stringify({ answer }));
  //   } else if (data.answer) {
  //     if (this.peerConnection) {
  //       await this.peerConnection.setRemoteDescription(new RTCSessionDescription(data.answer));
  //     }
  //   } else if (data.iceCandidate) {
  //     if (this.peerConnection) {
  //       await this.peerConnection.addIceCandidate(new RTCIceCandidate(data.iceCandidate));
  //     }
  //   }
  // }

  private async handleSignalingData(data: any) {
    if (data.offer) {
      if (!this.peerConnection) {
        this.initializePeerConnection();
      }
      if (this.peerConnection?.signalingState === 'stable') {
        await this.peerConnection.setRemoteDescription(new RTCSessionDescription(data.offer));
        const answer = await this.peerConnection.createAnswer();
        if (this.peerConnection) {
          await this.peerConnection.setLocalDescription(answer);
        }
        this.websocketService.enviarMensaje(JSON.stringify({ answer }));
        this.addIceCandidatesFromQueue();
      }
    } else if (data.answer) {
      if (this.peerConnection && this.peerConnection.signalingState === 'have-local-offer') {
        await this.peerConnection.setRemoteDescription(new RTCSessionDescription(data.answer));
        this.addIceCandidatesFromQueue();
      }
    } else if (data.iceCandidate) {
      if (this.peerConnection && this.peerConnection.remoteDescription) {
        await this.peerConnection.addIceCandidate(new RTCIceCandidate(data.iceCandidate));
      } else {
        this.iceCandidatesQueue.push(new RTCIceCandidate(data.iceCandidate));
      }
    }
  }

  private initializePeerConnection() {
    this.peerConnection = new RTCPeerConnection();

    this.peerConnection.ontrack = (event) => {
      this.remoteStreamSubject.next(event.streams[0]);
    };

    this.peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        this.websocketService.enviarMensaje(JSON.stringify({ iceCandidate: event.candidate }));
      }
    };
  }




  private async addIceCandidatesFromQueue() {
    if (this.peerConnection && this.peerConnection.remoteDescription) {
      while (this.iceCandidatesQueue.length > 0) {
        const candidate = this.iceCandidatesQueue.shift();
        await this.peerConnection.addIceCandidate(candidate!);
      }
    }
  }
}
