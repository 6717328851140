<div class="container">
  <div class="row">
    <div class="col-12 text-center">
      <button (click)="startRecording()">Start Recording</button>
      <button (click)="stopRecording()">Stop Recording</button>
    </div>
    <div class="col-12 text-center">
      <video #video width="640" height="300" controls autoplay></video>
      <video class="grabacion" *ngIf="recordedVideoUrl" [src]="recordedVideoUrl" controls></video>
    </div>
  </div>
</div>
