import { Component, OnInit } from '@angular/core';
import { WebsocketService } from '../services/websocket.service';

@Component({
  selector: 'app-carton',
  templateUrl: './carton.component.html',
  styleUrls: ['./carton.component.css']
})
export class CartonComponent implements OnInit {

  iniciar: boolean = true;
  btnAleatorio: boolean = false;

  numeroJugadores: number = 2;
  maxGanadores: number = 1;
  apuesta: number = 2;
  ganadores: number = 0;
  tablaBingo: any[][] = [];
  numeros: number[] = [];
  numerosAleatorios: number = 0;
  numeroGenerado: number = 0;
  numFinal: number = 0;
  numerosSeleccionados: number[] = [];

  constructor(private websocketService: WebsocketService) {}

  ngOnInit() {
    // Puedes poner lógica de inicialización aquí si es necesario
    this.websocketService.getMensajes().subscribe(mensaje => {
      if (mensaje['tipo']=='traerDatos') {
        this.enviarDatos();
      }
    });
  }
  enviarDatos(){
    let datosEnviar = {
      tablaBingo: this.tablaBingo,
      numeros: this.numeros,
      numerosSeleccionados: this.numerosSeleccionados,
      numeroGenerado: this.numeroGenerado,

      numeroJugadores: this.numeroJugadores,
      maxGanadores: this.maxGanadores,
      apuesta: this.apuesta,
      tipo: 'datosBingo'
    };
    this.websocketService.enviarMensaje(JSON.stringify(datosEnviar));
  }

  crearTabla() {
    this.iniciar=false;
    this.btnAleatorio=true;
    // Suponiendo que tienes estas variables en tu HTML como inputs
    const apuestaElement = (document.getElementById('apuesta') as HTMLInputElement);
    const numJugadoresElement = (document.getElementById('num_jugadores') as HTMLInputElement);
    const numGanadoresElement = (document.getElementById('num_ganadores') as HTMLInputElement);

    this.apuesta = parseInt(apuestaElement.value, 10);
    this.numeroJugadores = parseInt(numJugadoresElement.value, 10);
    this.maxGanadores = parseInt(numGanadoresElement.value, 10);

    let numero = 1;
    for (let fila = 0; fila < this.numeroJugadores; fila++) {
      const filaDatos: any[] = [];
      for (let columna = 0; columna < 5; columna++) {
        this.numeros.push(numero);

        filaDatos.push({
          id: 'num' + numero,
          clase: 'white-background',
          numero: numero,
          nombre: 'Disponible',
          puntos: '0'
        });
        numero++;
      }
      this.tablaBingo.push(filaDatos);
    }
    this.enviarDatos();
  }

  limpiar() {
    this.iniciar=true;
    this.btnAleatorio=false;

    this.tablaBingo = [];
    this.numeros=[];
    this.numerosSeleccionados=[];
    this.numeroGenerado=0;
    // Lógica para limpiar los datos anteriores si es necesario
  }

  manejarCambioNombre(event: any, numero: number) {
    const nuevoNombre = event.target.textContent;
    let e=0;
    for (let fila of this.tablaBingo) {
      for (let celda of fila) {
        if (celda.numero === numero) {
          let fil=(numero-1)-(e*5);
          this.tablaBingo[e][fil]['nombre']=nuevoNombre.trimStart();
          // webSocket
          let datosEnviar = {
            e: e,
            fil: fil,
            nuevoNombre: nuevoNombre.trimStart(),
            tipo: 'tablaBingo'
          };
          this.websocketService.enviarMensaje(JSON.stringify(datosEnviar));
          break;
        }
      }
      e+=1;
    }
    // Coloca el cursor al final del texto
    const elemento = event.target as HTMLElement;
    const range = document.createRange();
    const sel = window.getSelection();

    if (sel && elemento) {
      range.selectNodeContents(elemento);
      range.collapse(false); // `false` colapsa al final del contenido

      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  async generarNumero() {
    let numeroAleatorio = await this.aleatorios();
    // agregamos a la lista de numeros seleccionados
    this.numerosSeleccionados.push(numeroAleatorio);
    let e=0;
    for (let fila of this.tablaBingo) {
      for (let celda of fila) {
        if (celda.numero === numeroAleatorio) {
          let fil=(numeroAleatorio-1)-(e*5);
          this.tablaBingo[e][fil]['puntos'] = Number(this.tablaBingo[e][fil]['puntos']) + 1;
          // webSocket
          let datosEnviar = {
            e: e,
            fil: fil,
            puntos: this.tablaBingo[e][fil]['puntos'],
            numerosSeleccionados: this.numerosSeleccionados,
            tipo: 'puntos'
          };
          this.websocketService.enviarMensaje(JSON.stringify(datosEnviar));
          // su el jugador ya tiene 4 puntos se elinina de la lista de numeros aleatorios
          if (this.tablaBingo[e][fil]['puntos']==4) {
            this.numeros = this.numeros.filter(numero => numero !== numeroAleatorio);
            this.ganadores+=1;
            let datosEnviar = {
              numeros: this.numeros,
              tipo: 'numeroGanadore'
            };
            this.websocketService.enviarMensaje(JSON.stringify(datosEnviar));
            if (this.ganadores==this.maxGanadores) {
              this.btnAleatorio=false;
              alert("Termino el Juego");
            }
          }
          break;
        }
      }
      e+=1;
    }
  }

  aleatorios(): Promise<number> {
    return new Promise((resolve) => {
      for (let i = 0; i < 30; i++) {
        setTimeout(() => {
          this.numerosAleatorios = Math.floor(Math.random() * this.numeros.length);
          this.numeroGenerado = this.numeros[this.numerosAleatorios];

          // ENVIO WEB SOCKETS
          let datosEnviar = {
            numeroGenerado: this.numeroGenerado,
            tipo: 'numeroGenerado'
          };
          this.websocketService.enviarMensaje(JSON.stringify(datosEnviar));

          this.numFinal = this.numeroGenerado;
        }, 50 * i); // Retraso aumenta con cada iteración
      }
      setTimeout(() => {
        resolve(this.numFinal);
      }, 50 * 30); // Retraso para resolver la promesa
    });
  }

  getArray(length: number): any[] {
    if (length==0) {
      return [];
    }
    return new Array(length).fill(0);
  }

}
