import { Component, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GrabarService } from '../services/grabar.service';

@Component({
  selector: 'app-grabar',
  templateUrl: './grabar.component.html',
  styleUrls: ['./grabar.component.css']
})
export class GrabarComponent {
  @ViewChild('video') videoElement!: ElementRef<HTMLVideoElement>;
  mediaStream: MediaStream | undefined;
  recordedVideoUrl: any;

  constructor(
    private grabarService: GrabarService,
    private sanitizer: DomSanitizer
  ) {}
  
  async startRecording() {
    this.mediaStream = await this.grabarService.startRecording();
    if (this.videoElement && this.mediaStream) {
      this.videoElement.nativeElement.srcObject = this.mediaStream;
      this.videoElement.nativeElement.play();
      await this.grabarService.startCall();
    }
  }

  async stopRecording() {
    if (this.mediaStream) {
      const recordedBlob = await this.grabarService.stopRecording();
      this.recordedVideoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(recordedBlob));
      this.mediaStream.getTracks().forEach(track => track.stop());
      console.log('Recording URL:', this.recordedVideoUrl);
      this.downloadVideo(recordedBlob);
    } else {
      console.error("No hay una grabación en progreso.");
    }
  }

  downloadVideo(blob: Blob) {
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    const randomString = Math.random().toString(36).substring(2, 8);
    const filename = `live_${timestamp}_${randomString}.webm`;

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
}
