import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './tabla/home/home.component';
import { CartonComponent } from './tabla/carton/carton.component';
import { GrabarComponent } from './tabla/grabar/grabar.component';
import { LiveComponent } from './tabla/live/live.component';
import { WebSocketResolverService } from './tabla/services/web-socket-resolver.service';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'carton', component: CartonComponent },
  { path: 'grabar', component: GrabarComponent, resolve: { websocket: WebSocketResolverService } },
  { path: 'live', component: LiveComponent, resolve: { websocket: WebSocketResolverService } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
