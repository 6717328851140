import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private miWebSocket: WebSocket;
  private mensajesSubject = new Subject<any>();
  private isReadySubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.miWebSocket = new WebSocket("wss://157.230.89.213:4100");

    // this.miWebSocket.addEventListener("open", this.onOpen.bind(this));
    this.miWebSocket.addEventListener("open", () => {
      this.isReadySubject.next(true); // Mark WebSocket as ready
      console.log("WebSocket abierto.");
    });
    this.miWebSocket.addEventListener("message", this.onMessage.bind(this));
    this.miWebSocket.addEventListener("error", this.onError.bind(this));
    this.miWebSocket.addEventListener("close", this.onClose.bind(this));
  }

  isReady(): boolean {
    return this.isReadySubject.value;
  }

  private onOpen(event: Event) {
    console.log("WebSocket abierto.");
  }

  private async onMessage(event: MessageEvent) {
    console.log("WebSocket ha recibido un mensaje");
    const datos = await Object(JSON.parse(event.data));
    this.mensajesSubject.next(datos);
  }

  private onError(event: Event) {
    console.error("WebSocket ha observado un error: ", event);
  }

  private onClose(event: Event) {
    console.log("WebSocket cerrado.");
  }

  enviarMensaje(mensaje: any) {
    this.miWebSocket.send(mensaje);
  }

  getMensajes(): Observable<any> {
    return this.mensajesSubject.asObservable();
  }

  // onAbierto(): Observable<void> {
  //   return this.mensajesSubject.asObservable();
  // }

}
