<div class="container">
  <div class="row">
    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-12">
          <h1 class="Baskerville-h1">BINGO YAPERO </h1>
          <p class="regla Baskerville">Regla: OBTENER 4 VECES SU NUMERO ELEGIDO</p>
          <strong class="Baskerville">{{numeroJugadores*5}} JUGADORES - </strong> GANADORES <strong> {{maxGanadores}} </strong> - PREMIO <strong>S/. {{((numeroJugadores*5*apuesta)*0.75)/maxGanadores}}</strong>
        </div>
        <div class="col-12 text-center">
          <table id="bingoTable" class="table table-bordered table-striped-columns">
            <tr *ngFor="let fila of tablaBingo">
              <td *ngFor="let celda of fila" [ngClass]="{'blanco': celda.nombre === 'Disponible', 'celeste': celda.nombre !== 'Disponible', 'verde': celda.puntos == 4}">
                <div contenteditable="true" (input)="manejarCambioNombre($event, celda.numero)">
                  {{ celda.nombre }}
                </div>
                <div>{{ celda.numero }}</div>
                <div class="points"> <span *ngFor="let n of getArray(celda.puntos)" [ngClass]="{'point': celda.puntos < 4, 'point-g': celda.puntos >= 4}"> </span></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>


    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-12 text-center">
          <span class="yape_number">YP : 933-682-496</span>
        </div>
      </div>

      <div class="row" *ngIf="iniciar">
        <div class="col-3">
          <label label for="apuesta">Apuesta:</label>
          <select id="apuesta" [(ngModel)]="apuesta" class="form-select" value="2">
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
        <div class="col-3">
          <label for="num_jugadores">Jugadores:</label>
          <select id="num_jugadores" [(ngModel)]="numeroJugadores" class="form-select">
            <option value="2">10</option>
            <option value="3">15</option>
            <option value="4">20</option>
            <option value="5">25</option>
            <option value="6">30</option>
          </select>
        </div>
        <div class="col-3">
          <label for="num_ganadores">Ganadores:</label>
          <select id="num_ganadores" [(ngModel)]="maxGanadores" class="form-select">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
        <div class="col-3">
          <br>
          <button type="button" class="btn btn-outline-success" (click)="crearTabla()"> <i class="fa fa-table"></i> Generar</button>
        </div>
      </div>

      <div class="row" *ngIf="!iniciar">
        <div class="col-12 text-center">
          <br>
          <button type="button" class="btn btn-primary" (click)="limpiar()">Iniciar Nuevo Juego</button>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center">
          <h2 class="Baskerville">Número Generado</h2>
          <h1 class="generatedNumber">{{numeroGenerado}}</h1>
        </div>
        <div class="col-12 text-center">
          <br>
          <button type="button" class="btn btn-outline-info" (click)="generarNumero()" [disabled]="!btnAleatorio">Aleatorio</button>
        </div>

        <div class="col-12 text-center">
          <h2 class="Baskerville">Numeros Generados</h2>
          <span *ngFor="let num of numerosSeleccionados" [ngClass]="{'num_generados': numeros.includes(num), 'num_ganador': !numeros.includes(num)}"> {{num}} </span>
        </div>

        <div class="col-12 text-center">
          <h2 class="Baskerville">Lista de Ganadores</h2>
        </div>
        <div class="col-12">
          <p *ngFor="let fila of tablaBingo" class="mb-1">
            <ng-container *ngFor="let celda of fila">
              <p *ngIf="celda.puntos>=4" class="mb-1">
                {{celda.numero}} - {{celda.nombre}}
              </p>
            </ng-container>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


